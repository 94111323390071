import $ from 'jquery';
import stickyFooter from 'sticky-footer';
import SmoothScroll from 'smooth-scroll';
import Tabby from 'tabbyjs';
import headroomJS from "headroom.js";
import sal from "sal.js";
import LazyLoad from "vanilla-lazyload";
import 'bootstrap/js/src/dropdown';
import vdt from './modules/jq-vertical-dot-nav';
import addBrowserClass from './modules/browser';
import fontSetting from './modules/font';
import sidePanel from 'side-panel-menu-thing';

// -------------------------------------------------------------------
// sidePanel JS
// -------------------------------------------------------------------
document.addEventListener("DOMContentLoaded", function() {
    var mainPanel = sidePanel({
        content: document.getElementById('offCanvas'),
        target: document.body,
        duration: 450,
        width: 300,
        dragOpen: true,
        preventScroll: true
    })
    document.getElementById('open').addEventListener('click', mainPanel.show);
}, false);

// -------------------------------------------------------------------
// stickyFooter JS
// -------------------------------------------------------------------
stickyFooter.init({
    selectorWrap: '[data-sticky-wrap]', // Selector for the wrap container (must use a valid CSS selector)
    selectorFooter: '[data-sticky-footer]', // Selector for the footer (must use a valid CSS selector)
    callback: function() {}, // Runs after the footer is stuck
});
// -------------------------------------------------------------------
// SmoothScroll JS
// -------------------------------------------------------------------
const scroll = new SmoothScroll('a[href*="#"]', {
    header: '.l-header',
    speed: 50,
    speedAsDuration: false
});
// scroll.animateScroll(80);

// -------------------------------------------------------------------
// Tabby JS
// -------------------------------------------------------------------
if ($('[data-tabs]').length) {
    const tabs = new Tabby('[data-tabs]');
} else {}
if ($('[data-tabs-Bd]').length) {
    const tabsBd = new Tabby('[data-tabs-Bd]');
} else {}
if ($('[data-tabsCompass]').length) {
    const tabsBd = new Tabby('[data-tabsCompass]');
} else {}

// -------------------------------------------------------------------
// LazyLoad
// -------------------------------------------------------------------
const lazyContent = new LazyLoad({
    elements_selector: "img.lazy",
    use_native: true // <-- there you go
});
const lazyBackground = new LazyLoad({
    elements_selector: "iframe.lazy, video.lazy, div.lazy"
        // DON'T PASS use_native: true HERE
});

// -------------------------------------------------------------------
// Sal JS
// -------------------------------------------------------------------
sal();


// -------------------------------------------------------------------
// jQuery
// -------------------------------------------------------------------
$(function() {
    $('.is-section').verticalDotNav();
});